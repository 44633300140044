<template>
  <v-container>
    <div class="d-flex justify-space-between">
      <div/>
      <div >
        <v-tabs
            v-model="tab"
            background-color="white"
            centered
            class="mx-auto"
            icons-and-text
            style="width: fit-content"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
              href="#in-progress"
          >
            In Progress
          </v-tab>
          <v-tab
              href="#open"
          >
            Open
          </v-tab>
          <v-tab
              href="#resolved"
          >
            Resolved
          </v-tab>
          <v-tab
              href="#all"
          >
            All
          </v-tab>
        </v-tabs>
      </div>
      <div class="text-lg-right d-flex align-content-center justify-end gap-x-4">
        <v-btn
            height="48"
            style="background-color: #4facae;color: white"
            elevation="0"
            @click="$router.push({name:'CreateSupportTicket'})"
        >
          Generate Ticket
        </v-btn>
      </div>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item value="in-progress">
        <div id="in-progress" class="section">
          <SupportTicketsTable :filter="tab"/>
        </div>
      </v-tab-item>
      <v-tab-item value="open">
        <div id="open" class="section">
          <SupportTicketsTable :filter="tab"/>
        </div>
      </v-tab-item>

      <v-tab-item value="resolved">
        <div id="resolved" class="section">
          <SupportTicketsTable :filter="tab"/>
        </div>
      </v-tab-item>

      <v-tab-item value="all">
        <div id="all" class="section">
          <SupportTicketsTable :filter="tab"/>
        </div>
      </v-tab-item>
    </v-tabs-items>

  </v-container>
</template>

<script>
import SupportTicketsTable from "@/views/CustomerSupport/SupportTicketsTable.vue";

export default {
  name: "CustomerSupport",
  components: {SupportTicketsTable},
  data() {
    return {
      tab: 'in-progress',
    };
  },
};
</script>
