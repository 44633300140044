<script>
export default {
  name: "SupportTicketsTable",
  props: {
    filter: String,
  },
  data() {
    return {
      tickets: [],
      page: 1,
      totalPages: 1,
      perPage: 10,
    }
  },
  watch: {
    tab() {
      this.fetchTickets()
    },
    page(){
      this.fetchTickets()
    }
  },
  created() {
    this.fetchTickets()
  },
  methods: {
    fetchTickets() {
      this.$http
          .get("venues/support/customer-support", {
            params: {
              page: this.page,
              per_page: this.perPage,
              status: this.filter
            }
          })
          .then((response) => {
            if (response.status == 200) {
              const records = response.data.data
              this.tickets = records.data;
              this.page = records.current_page
              this.totalPages = records.total
              this.perPage = records.per_page
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    encodeId(id){
      return btoa(id);
    }
  }
}
</script>

<template>
  <v-card class="shadow px-8 py-4 rounded-5 mt-8">
    <div class="mb-2" style="font-size: 18px;font-weight: 600">
      Your Tickets
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr class="tr-rounded tr-neon opacity-70">
          <th>Ticket Number</th>
          <th>Date Created</th>
          <th>Category</th>
          <th>Requester Name</th>
          <th>Subject</th>
          <th>Last Activity</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="data in tickets" :key="data.id">
          <td>
            <RouterLink :to="{name:'CustomerSupportDetails',params:{id:encodeId(data.id)}}">
              TK-{{ data.id }}
            </RouterLink>
          </td>
          <td>
            {{ data.created_at | dayDateFormat }}
          </td>
          <td>
            {{ data.category }}
          </td>
          <td>
            {{ data.first_name }}
            <template v-if="data.last_name">
              {{ data.last_name }}
            </template>
          </td>
          <td>
            {{ data.subject.slice(0, 100) }}
          </td>
          <td>
            {{ data.updated_at | dayDateFormat }}
          </td>
          <td >
            <span :class="`support-status ${data.status.name.toLowerCase()}`">
              {{ data.status.name }}
            </span>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
        v-if="totalPages"
        v-model="page"
        :length="Math.ceil(totalPages/perPage)"
    ></v-pagination>
  </v-card>
</template>

<style scoped lang="scss">

.tr-rounded {
  th:first-child, td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  th:last-child, td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.tr-neon {
  td, th {
    background: #4FAEAF !important;
  }

  &.opacity-70 {
    td, th {
      background: rgba(233, 241, 246, 0.7) !important;
    }
  }
}

.shadow {
  box-shadow: 0 4px 8px 0 rgba(70, 76, 136, 0.20) !important;
}

.v-card{
  margin: 8px !important;
}
.rounded-5 {
  border-radius: 1.25rem !important;
}
th{
  font-size: 11px !important;
}

</style>
